<template>
  <div class="page">
    <HeadBar class="mine-headbar" title="会员中心" />

    <div class="mine">
      <MineVipc from="profile" />
    </div>
  </div>
</template>

<script>
import MineVipc from '@/pages/mine/components/vipc.vue'
import { useShareBrowserSize } from '@/composables/sharedComposable'

export default {
  name: 'Mine',
  components: {
    MineVipc,
  },
  setup() {
    const { browserType } = useShareBrowserSize()

    return {
      browserType,
    }
  },
}
</script>

<style lang="stylus" scoped>
.page
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding-top calc(150px + 32px) !important 
.mine
  width 100%
  padding 0 100px
  height calc(100vh - 164px)
  overflow-y scroll
  ::-webkit-scrollbar
    display none
  &-singed
    margin-top 0
    padding-bottom 250px
    &-title
      color: rgba(255, 255, 255, 0.60);
      padding 64px 0 20px
      border-bottom 2px solid rgba(255, 255, 255, 0.1)
      span
        font-size 32px
        @media screen and (max-width 1200px) and (min-height 1200px)
          font-size 26px
    .song-list
      padding 0
      height auto!important
      &::-webkit-scrollbar
        display: none
      @media screen and (max-width 1200px) and (min-height 1200px)
        min-height 304px
        overflow hidden
        padding-top 32px
        ::v-deep .song-block, ::v-deep .song-item
          .name
            font-size 26px
          .desc
            font-size 20px
          .song-block-vip
            width 42px
            height 22px
    &-empty
      padding-bottom 50px
      h3
        width 480px
        text-align center
        color rgba(255, 255, 255, 0.4)
        font-size: 28px;
        padding-top 130px
        background url(https://qncweb.ktvsky.com/20231208/other/8735391456ed4d23857d801ce6c5482b.svg) no-repeat top center
        background-size 90px auto
        margin 96px auto 80px
      ::v-deep .sec-gusse-sing
        padding 0!important
        &-list
          display grid
          grid-template-columns repeat(3, 493px)
          justify-content space-between
          @media screen and (max-width 1200px) and (min-height 1200px)
            grid-template-columns repeat(3, 344px)
            min-height 304px
            overflow hidden
            ::v-deep .song-item
              width 344px
              height 140px
              margin-bottom 24px
              .name
                font-size 26px
              .desc
                font-size 20px
              .song-block-vip
                width 42px
                height 22px
          .song-block
            width 100%
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding 0
.page
  height 100vh
  overflow hidden
  .search-bar
    background: none
  .mine-headbar
    background: none
  // .mine-vip-v
  //   @media screen and (max-width 1200px) and (min-height 1200px)
  //     position absolute
  //     top 785px
  //     left 60px
  //     width 1080px
  //     height 514px
  //     z-index 1
  //     zoom 1
  //     ::v-deep
  //       .user-vip-openvip
  //         zoom 0.7
  //       .user-vip-entry
  //         div
  //           &:nth-child(1)
  //             margin-right 0
  //             // display none
  //           &:nth-child(2)
  //             // display flex
</style>
